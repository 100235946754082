import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import withStyles from '@material-ui/styles/withStyles';
import { DrcButton, DrcInput, DrcPageWarning, DrcSelect } from 'driscolls-react-components';
import { DuDateUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getSavedRanchDetails, setRanchDetailsData, setUserProducingArea } from '../../actions/OverrideActions';
import { setPageDirty } from '../../actions/actions';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import {
    END_DATE,
    END_DATE_PLACEHOLDER,
    OVERRIDE_ADD,
    PRODUCING_AREA,
    PRODUCING_AREA_PLACEHOLDER,
    RANCH_NUMBER,
    RANCH_NUMBER_PLACEHOLDER,
    START_DATE,
    START_DATE_PLACEHOLDER
} from '../../language/english';
import APIEndPoints from '../../services/api';
import { endDate, startDate } from '../../util/validation';
import DrcDatePicker from './DrcDatePicker';
import { getPayload } from '../../util/utils';
import { withAuth } from '@driscollsinc/login-module-ui';

const PAGE_TYPE = 'override';
let styles = () => ({
    dialog: {
        '& .MuiToolbar-root': {
            display: 'none'
        }
    }
});

const OverrideHeader = (props) => {
    const [error, setError] = useState({
        isError: false,
        errorMessage: ''
    });

    const [overrideData, setOverrideData] = useState({
        ranchNbr: '',
        Name: '',
        producingArea: '',
        isInitialRanchNumber: true,
        StartDate: DuDateUtilities.ToIsoDate(new Date()),
        validStartDate: true,
        disableStartChanges: true,
        EndDate: null,
        validEndDate: true,
        disableEndChanges: false,
        isError: false,
        errorMessage: '',
        isRanchNumberDisabled: true,
        isValidRanchNumber: false,
        isValidProducingArea: false
    });

    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: ''
    });

    const [previousProducingArea, setPreviousProducingArea] = useState('');

    useEffect(() => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'managerMaintenance',
                token,
                APIEndPoints.GET_USER_PRODUCING_AREAS(props.loggedInUser),
                'GET',
                { Type: 'managerMaintenance' },
                { loadingMessage: 'Fetching assigned producing area...' }
            )
                .then((data) => {
                    if (data && data.length) {
                        props.setUserProducingArea(data);
                    } else {
                        props.setUserProducingArea([]);
                    }
                })
                .catch((err) => {
                    let message = err.data.response.data.message || 'Something happened, but I am not sure if it is good or bad!';
                    props.setManagerMaintenanceData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    }, []);
    const showErrorMessage = (message) => {
        setError({
            isError: true,
            errorMessage: message
        });
        setTimeout(() => {
            hideErrorMessage();
        }, 3000);
    };
    const hideErrorMessage = () => {
        setError({
            isError: false,
            errorMessage: ''
        });
    };
    const handleStartDate = (event) => {
        props.setPageDirty(true);
        console.log(event);
        let oStartDate = startDate(event, overrideData);
        if (oStartDate.showErrorMessage) {
            showErrorMessage(oStartDate.errorMessage);
        }
        let disableStartChanges;
        if (oStartDate.StartDate === 'N/A') {
            disableStartChanges = false;
        } else {
            disableStartChanges = oStartDate.disableStartChanges;
        }
        setOverrideData({
            ...overrideData,
            StartDate: oStartDate.StartDate,
            validStartDate: oStartDate.validStartDate,
            disableStartChanges: disableStartChanges,
            startDateHelperText: oStartDate.helperText
        });
    };

    const handleEndDate = (event) => {
        props.setPageDirty(true);
        let oEndDate = endDate(event, overrideData);

        if (oEndDate.showErrorMessage) {
            showErrorMessage(oEndDate.errorMessage);
        }
        let disableEndChanges;
        if (oEndDate.EndDate === 'N/A') {
            disableEndChanges = false;
        } else {
            disableEndChanges = oEndDate.disableEndChanges;
        }
        setOverrideData({
            ...overrideData,
            EndDate: oEndDate.EndDate,
            validEndDate: oEndDate.validEndDate,
            disableEndChanges: disableEndChanges,
            endDateHelperText: oEndDate.helperText
        });
    };
    const resetValues = () => {
        setOverrideData({
            producingArea: '',
            ranchNbr: '',
            Name: '',
            isValidRanchNumber: false,
            isInitialRanchNumber: true,
            StartDate: DuDateUtilities.ToIsoDate(new Date()),
            validStartDate: true,
            disableStartChanges: true,
            EndDate: null,
            validEndDate: true,
            disableEndChanges: false
        });
        setError({
            isError: false,
            errorMessage: ''
        });
        props.setPageDirty(false);
    };
    const handleAdd = () => {
        let override = {
            RanchNbr: overrideData.ranchNbr.label,
            RanchName: overrideData.ranchNbr.value,
            StartDate: overrideData.StartDate,
            EndDate: overrideData.EndDate
        };
        let overridePayload = getPayload(override);
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.OVERRIDE_DATA, 'POST', overridePayload, { loadingMessage: 'Creating ranch override...' })
                .then((data) => {
                    setMessageDialog({
                        showMessage: true,
                        severity: data.Message ? 'success' : 'error',
                        message: data.Message || 'Something happened. but I am not sure if it is good or bad!'
                    });
                    props.getData();
                    resetValues();
                })
                .catch((err) => {
                    let message = err.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                    resetValues();
                });
        });
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleProducingAreaChange = (option) => {
        props.setPageDirty(true);
        if (previousProducingArea.value !== option.value) {
            setPreviousProducingArea(option);
            if (option) {
                setOverrideData({
                    ...overrideData,
                    producingArea: option,
                    ranchNbr: '',
                    Name: '',
                    isValidRanchNumber: false,
                    isValidProducingArea: true,
                    isRanchNumberDisabled: false
                });
                let aSavedRanchDetails = props.ranchDetails.filter((ranchItem) => (ranchItem[option.value] ? ranchItem[option.value] : undefined));
                if (!aSavedRanchDetails || !aSavedRanchDetails.length) {
                    props.auth.getAccessToken().then((token) => {
                        Middleware.Send(
                            'ranchDetails',
                            token,
                            APIEndPoints.RANCH_DETAILS(option.value),
                            'GET',
                            { Type: 'ranchDetails' },
                            { loadingMessage: 'Fetching ranch details...' }
                        ).then((res) => {
                            if (res.Data.length) {
                                props.setRanchDetailsData(option.value, res.Data);
                            }
                        });
                    });
                } else {
                    props.getSavedRanchDetails(aSavedRanchDetails[0][option.value]);
                }
            } else {
                props.setRanchDetailsData(option);
                setOverrideData({
                    ...overrideData,
                    producingArea: '',
                    ranchNbr: '',
                    Name: '',
                    isValidRanchNumber: false,
                    isValidProducingArea: false,
                    isRanchNumberDisabled: true
                });
            }
        }
    };

    const handleRanchNbrChange = (option) => {
        props.setPageDirty(true);
        if (option) {
            const aMatchcedRanch = props.ranchDetailOptions.filter((item) => item.label === option.label);
            setOverrideData({
                ...overrideData,
                ranchNbr: option,
                Name: aMatchcedRanch[0].value,
                isValidRanchNumber: true,
                isValidProducingArea: true
            });
        } else {
            setOverrideData({
                ...overrideData,
                ranchNbr: '',
                Name: '',
                isValidRanchNumber: false,
                isValidProducingArea: true
            });
        }
    };

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
    return (
        <React.Fragment>
            {error.isError ? <DrcPageWarning anchorVertical="top" message={error.errorMessage} /> : null}
            <Grid container>
                <Grid container spacing={3} justify="space-between" alignItems="flex-start">
                    <Grid item xs={6} sm={3} md={3} lg={3}>
                        <DrcSelect
                            required
                            isClearable={true}
                            name="ProducingArea"
                            label={PRODUCING_AREA}
                            value={overrideData.producingArea}
                            onChange={handleProducingAreaChange}
                            options={props.userProducingArea}
                            placeholder={PRODUCING_AREA_PLACEHOLDER}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3} lg={3}>
                        <DrcSelect
                            required
                            isClearable={true}
                            name="RanchNbr"
                            label={RANCH_NUMBER}
                            value={overrideData.ranchNbr}
                            onChange={handleRanchNbrChange}
                            options={props.ranchDetailOptions}
                            placeholder={RANCH_NUMBER_PLACEHOLDER}
                            isDisabled={overrideData.isRanchNumberDisabled}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <DrcInput
                            label="Ranch Name"
                            value={overrideData.Name}
                            InputLabelProps={{ shrink: true }}
                            name="Name"
                            readOnly={true}
                        ></DrcInput>
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <DrcDatePicker
                            disableToolbar={true}
                            variant="inline"
                            label={START_DATE}
                            placeholder={START_DATE_PLACEHOLDER}
                            InputLabelProps={{ shrink: true }}
                            value={overrideData.StartDate}
                            helperText={overrideData.startDateHelperText}
                            onChange={handleStartDate}
                            minDate={new Date()}
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <DrcDatePicker
                            required
                            label={END_DATE}
                            placeholder={END_DATE_PLACEHOLDER}
                            InputLabelProps={{ shrink: true }}
                            value={overrideData.EndDate}
                            onChange={handleEndDate}
                            helperText={overrideData.endDateHelperText}
                            minDate={new Date()}
                            autoOk
                            disableToolbar={true}
                            variant="inline"
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <DrcButton
                            floatRight
                            isPrimary
                            disabled={
                                !overrideData.isValidProducingArea ||
                                !overrideData.isValidRanchNumber ||
                                !overrideData.disableStartChanges ||
                                !overrideData.disableEndChanges
                            }
                            onClick={handleAdd}
                        >
                            {OVERRIDE_ADD}
                        </DrcButton>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        ranchDetailOptions: state.overrideReducer.ranchDetailOptions,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        ranchDetails: state.overrideReducer.ranchDetails,
        userProducingArea: state.overrideReducer.userProducingArea,
        loggedInUser: state.masterReducer.loggedInUser
    };
};

export default connect(mapStateToProps, { setRanchDetailsData, getSavedRanchDetails, setPageDirty, setUserProducingArea })(
    withAuth(withStyles(styles)(OverrideHeader))
);
