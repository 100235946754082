import { i18next, reacti18next } from '@driscollsinc/style-guide-web2.0';
import { defaultLanguageHandler } from './utils/languageHelper';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export enum NAMESPACE {
    COMMON = 'common',
    USER_MANAGEMENT = 'qmpUserManagement',
    SITES = 'qmpSites'
}

const defaultLanguage = defaultLanguageHandler();

i18next
    .use(LanguageDetector)
    .use(Backend)
    .use(reacti18next.initReactI18next)
    .init({
        lng: defaultLanguage,
        fallbackLng: false,
        defaultNS: NAMESPACE.COMMON,
        ns: [NAMESPACE.COMMON, NAMESPACE.USER_MANAGEMENT, NAMESPACE.SITES],
        returnObjects: true,
        interpolation: { escapeValue: false },
        react: { useSuspense: false },
        backend: {
            loadPath: `${window.config.ASSETS_URL}/translations/{{lng}}/{{ns}}.json`,
            crossDomain: true
        }
    });

export default i18next;
