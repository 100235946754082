import { Grid } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import AddIcon from '@material-ui/icons/Add';
import MuiAlert from '@material-ui/lab/Alert';
import withStyles from '@material-ui/styles/withStyles';
import BerryTypeSelect from '../../components/BerryTypeSelect';
import DispositionTable from '../../components/Disposition/DispositionTable';
import DrcMultiSelect from '../../components/DrcMultiSelect';
import { DrcButton, DrcDialog, DrcPanel, DrcSelect, DrcTooltip } from 'driscolls-react-components';
import cloneDeep from 'lodash/cloneDeep';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setDispositionData, setFactorType } from '../../actions/DispositionAction';
import { BERRY_TYPE, BERRY_TYPE_PLACEHOLDER, DONATION, FACTOR_TYPE, PICKUP, PROCESSING, PRODUCING_AREA_PLACEHOLDER } from '../../language/english';
import APIEndPoints from '../../services/api';
import CheckboxFormatter from './CheckboxFormatter';
import { withAuth } from '@driscollsinc/login-module-ui';

let tableRowIndex = 0;

let styles = (theme) => ({
    gridTitle: {
        marginTop: '0px',
        marginBottom: '8px',
        fontSize: '21px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#4a773c'
    },
    gridCount: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#6f5091;'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    },
    actionButton: {
        minWidth: '40px',
        display: 'block',
        margin: '0px !important',
        padding: '9px 8px 3px'
    },
    actionButtonError: {
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    },
    layout: {
        width: '97%'
    },
    tableContainer: {
        width: '100%',
        display: 'grid'
    }
});

const PAGE_TYPE = 'Disposition Options';

const DispositionOptions = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(true);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [cancelEnabled, setCancelEnabled] = useState(false);
    const [localDispositionData, setLocalDispositionData] = useState([]);
    const [AddMode, setAddMode] = useState(false);
    const [factorType, setFactorsType] = useState([]);
    const [BerryType, setBerryType] = useState(null);
    const [producingAreaFilter, setProducingAreaFilter] = useState([]);
    const [producingAreaByUser, setProducingAreaByUser] = useState([]);

    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: ''
    });

    let factorTypeFromProps = props.factorType;

    const getFactorTypeOptions = () => {
        let factorTypeFilter = localDispositionData.map((e) => e.FactorType);
        let factorTypeFilterTwo = factorTypeFromProps.map((e) => e.value);
        return factorTypeFilterTwo
            .filter((o) => factorTypeFilter.indexOf(o) === -1)
            .map((item) => {
                return { value: item, label: item };
            });
    };

    // const cellFormatter = ({ value, row }, col) => {
    //     let column = col;
    //     if (column === 'Donation') {
    //         return (
    //             <CheckboxFormatter selected={value === 1} onSelectionChange={(event, selected) => handleChange(event, selected, 'Donation', row)} />
    //         );
    //     } else if (column === 'Processing') {
    //         return (
    //             <CheckboxFormatter selected={value === 1} onSelectionChange={(event, selected) => handleChange(event, selected, 'Processing', row)} />
    //         );
    //     } else if (column === 'Pickup') {
    //         return <CheckboxFormatter selected={value === 1} onSelectionChange={(event, selected) => handleChange(event, selected, 'Pickup', row)} />;
    //     } else {
    //         return (
    //             <span style={{ fontWeight: '500', fontSize: '13px' }} title={value}>
    //                 {value}
    //             </span>
    //         );
    //     }
    // };

    const getColumns = () => {
        return [
            {
                accessorKey: 'FactorType',
                header: (
                    <DrcTooltip tipText={FACTOR_TYPE}>
                        <span>{FACTOR_TYPE}</span>
                    </DrcTooltip>
                )
            },
            {
                accessorKey: 'Donation',
                header: (
                    <DrcTooltip tipText="DONATION">
                        <span>{DONATION}</span>
                    </DrcTooltip>
                ),

                cell: (data) => {
                    const value = data.getValue();
                    const row = data.row.original;
                    return (
                        <CheckboxFormatter
                            selected={value === 1}
                            onSelectionChange={(event, selected) => handleChange(event, selected, 'Donation', row)}
                        />
                    );
                }
            },
            {
                accessorKey: 'Processing',
                header: (
                    <DrcTooltip tipText="Processing">
                        <span>{PROCESSING}</span>
                    </DrcTooltip>
                ),
                cell: (data) => {
                    const value = data.getValue();
                    const row = data.row.original;
                    return (
                        <CheckboxFormatter
                            selected={value === 1}
                            onSelectionChange={(event, selected) => handleChange(event, selected, 'Processing', row)}
                        />
                    );
                }
            },
            {
                accessorKey: 'Pickup',
                header: (
                    <DrcTooltip tipText="Pickup">
                        <span>{PICKUP}</span>
                    </DrcTooltip>
                ),
                cell: (data) => {
                    const value = data.getValue();
                    const row = data.row.original;
                    return (
                        <CheckboxFormatter
                            selected={value === 1}
                            onSelectionChange={(event, selected) => handleChange(event, selected, 'Pickup', row)}
                        />
                    );
                }
            }
        ];
    };

    const handleChange = (_event, selected, column, row) => {
        row[column] = selected ? 1 : 0;
        let item = localDispositionData.find((e) => e.FactorType === row.FactorType);
        if (!item) {
            row.ProducingAreaCode = producingAreaCode[0];
            localDispositionData.push(row);
        } else {
            let newData = cloneDeep(localDispositionData);
            newData.forEach((e) => {
                if (e.FactorType === row.FactorType) {
                    e = item;
                }
            });
            setLocalDispositionData([...newData]);
        }

        setCancelEnabled(true);
        setSaveEnabled(true);
    };

    const onCancel = () => {
        setAddMode(false);
        setFactorsType('');
    };

    const handleAddRow = () => {
        tableRowIndex = parseFloat(tableRowIndex) + 1;
        let updatedRows = { FactorType: factorType.value, BerryType: BerryType.label, Donation: 0, Processing: 0, Pickup: 0 };
        let newData = cloneDeep(localDispositionData);
        newData.push(updatedRows);

        setLocalDispositionData([...newData]);
        props.setDispositionData([...newData]);
        setAddMode(false);
        setSaveEnabled(true);
        setCancelEnabled(true);
        setFactorsType('');
    };

    useEffect(() => {
        if (producingAreaFilter && BerryType) {
            getData(producingAreaFilter.value, BerryType.label);
        }
    }, [BerryType, producingAreaFilter]);

    useEffect(() => {
        getMLookupData();
    }, [AddMode]);

    useEffect(() => {
        getProducingArea((data) => {
            if (data.length !== 0) {
                let procuingArea = data.map((item) => {
                    return { value: item.ProducingArea, label: item.ProducingAreaCode };
                });
                setProducingAreaByUser(
                    procuingArea.sort(function (a, b) {
                        return Number(a.value - b.value);
                    })
                );
            } else {
                setProducingAreaByUser([]);
            }
        });
    }, []);

    const getData = (producingareaCode, berryType) => {
        props.auth.getAccessToken().then((token) => {
            setRefreshGrid(true);
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.GET_DISPOSITION(producingareaCode, berryType),
                'GET',
                { Type: PAGE_TYPE },
                { loadingMessage: 'Fetching Disposition Options...' }
            )
                .then((data) => {
                    if (data && data.length) {
                        props.setDispositionData(data);
                        setLocalDispositionData(cloneDeep(data));
                    } else {
                        props.setDispositionData([]);
                        setLocalDispositionData(cloneDeep(data));
                    }
                    setRefreshGrid(false);
                })
                .catch((error) => {
                    setRefreshGrid(false);
                    let message = error.data.response.data.message || 'Something happened, but I am not sure if it is good or bad!';
                    props.setDispositionData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    let producingAreaCode = producingAreaFilter.label ? producingAreaFilter.label.split('-') : '';

    const addData = () => {
        let defaultOptions = ['Donation', 'Processing', 'Pickup'];
        let payload = {
            ProducingAreaCode: producingAreaCode[0],
            ProducingAreaName: producingAreaCode[1],
            BerryType: BerryType.label,
            BerryCode: BerryType.value,
            CreatedBy: props.loggedInUser,
            ModifiedBy: props.loggedInUser,
            OptionList: []
        };

        payload.OptionList = localDispositionData.map((item) => {
            return {
                FactorType: item.FactorType,
                Option: cloneDeep(defaultOptions).filter((e) => item[e] === 1)
            };
        });

        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.SAVE_DISPOSITION_OPTION, 'POST', payload, { loadingMessage: 'Saving data' })
                .then((data) => {
                    setMessageDialog({
                        showMessage: true,
                        severity: data.Message ? 'success' : 'error',
                        message: data.Message || 'Something happened. but I am not sure if it is good or bad!'
                    });
                    setSaveEnabled(false);
                    setCancelEnabled(false);
                    getData(producingAreaFilter.value, BerryType.label);
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const getProducingArea = (callback) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(
                'managerMaintenance',
                token,
                APIEndPoints.GET_USER_PRODUCING_AREAS(props.loggedInUser),
                'GET',
                { Type: 'managerMaintenance' },
                { loadingMessage: '' }
            )
                .then((data) => {
                    if (data && data.length) {
                        callback(data);
                    } else {
                        callback([]);
                    }
                })
                .catch(() => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';
                    callback([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const handleOnChangeOfWareHouse = (option) => {
        setProducingAreaFilter(option);
    };
    const handleBerryTypeChange = (berryType) => {
        setBerryType(berryType);
    };

    const getMLookupData = () => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.GET_M_LOOKUP('Factor Types'), 'GET', { Type: PAGE_TYPE }, { loadingMessage: '' })
                .then((data) => {
                    if (data && data.length) {
                        props.setFactorType(data);
                    } else {
                        props.setFactorType([]);
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened, but I am not sure if it is good or bad!';
                    props.setFactorType([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessageDialog({ showMessage: false });
    };
    const handleCancel = () => {
        if (producingAreaFilter.value && BerryType.label) {
            getData(producingAreaFilter.value, BerryType.label);
            setSaveEnabled(false);
            setCancelEnabled(false);
        }
    };
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }}
            className={props.classes.layout}
        >
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                    <DrcMultiSelect
                        options={producingAreaByUser}
                        value={producingAreaFilter}
                        label="Producing Area"
                        placeholder={PRODUCING_AREA_PLACEHOLDER}
                        isMulti={false}
                        onChange={handleOnChangeOfWareHouse}
                        name="producingArea"
                    ></DrcMultiSelect>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                    <BerryTypeSelect
                        label={BERRY_TYPE}
                        options={props.berryType}
                        value={BerryType}
                        placeholder={BERRY_TYPE_PLACEHOLDER}
                        onChange={handleBerryTypeChange}
                    />
                </Grid>
            </Grid>

            <DrcButton
                size="small"
                disabled={!producingAreaFilter || !BerryType}
                floatRight
                isSecondary
                style={{ width: 'auto', border: 'none' }}
                onClick={() => setAddMode(true)}
            >
                <AddIcon />
                ADD FACTOR TYPE
            </DrcButton>
            <div className={props.classes.tableContainer}>
                {!refreshGrid && localDispositionData && localDispositionData.length > 0 ? (
                    <DispositionTable rows={localDispositionData || []} columns={getColumns()}></DispositionTable>
                ) : null}
                <div style={{ backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'right' }}>
                    <DrcButton disabled={!cancelEnabled} style={{ width: '150px' }} isSecondary onClick={() => handleCancel()} floatRight>
                        Cancel
                    </DrcButton>
                    <DrcButton style={{ width: '150px' }} isPrimary onClick={addData} floatRight disabled={!saveEnabled}>
                        Save
                    </DrcButton>
                </div>
            </div>

            <DrcDialog
                open={AddMode}
                title={`Add: ${PAGE_TYPE}`}
                buttons={
                    <div>
                        <DrcButton isSecondary onClick={onCancel} floatRight>
                            Cancel
                        </DrcButton>
                        <DrcButton isPrimary onClick={handleAddRow} floatRight disabled={!factorType.value}>
                            ADD
                        </DrcButton>
                    </div>
                }
            >
                <hr />
                <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                    <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                        <DrcSelect
                            name={'factorType'}
                            label={'Factor Type'}
                            options={getFactorTypeOptions()}
                            value={factorType}
                            onChange={(e) => {
                                setFactorsType(e);
                            }}
                            placeholder={'Select Factor Type'}
                        />
                    </div>
                </div>
            </DrcDialog>

            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </DrcPanel>
    );
};

const mapStateToProps = (state) => {
    return {
        pageTitle: state.rootReducer.pageTitle,
        dispositionDetails: state.dispositionReducer.dispositionDetails,
        userProducingArea: state.RejectionThresholdReducer.userProducingArea,
        userGroup: state.masterReducer.userGroup,
        berryType: state.pointMatrixReducer.berryType,
        factorType: state.dispositionReducer.factorType,
        loggedInUser: state.masterReducer.loggedInUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    setDispositionData: (data) => dispatch(setDispositionData(data)),
    setFactorType: (data) => dispatch(setFactorType(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(DispositionOptions)));
