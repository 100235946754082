import { PropsWithChildren, useEffect, useState } from 'react';
import { DuAuthenticationUtilities } from '../legacy/DriscollsReactUtilities';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@driscollsinc/login-module-ui';

interface GroupBaseProtectProps {
    groupsAllowed: any[];
    businessUnit?: 'dota' | 'demea';
}

const GroupBaseProtect = (props: PropsWithChildren<GroupBaseProtectProps>) => {
    const { children, groupsAllowed, businessUnit = 'dota' } = props;
    const { authState } = useAuth();
    const [groups, setGroups] = useState([]);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const checkForGroups = () => {
        if (pathname.includes('demea') && businessUnit === 'dota') {
            return;
        }

        if (!authState) {
            navigate('/');
            return;
        }
        if (!authState?.isAuthenticated) {
            if (groups.length != 0) {
                setGroups([]);
            }
            navigate('/');
        } else {
            const tokenGroups = DuAuthenticationUtilities.GetGroups(authState?.accessToken) || [];
            if (groups.length != tokenGroups.length) {
                setGroups(tokenGroups);
            }
        }
    };

    useEffect(() => {
        checkForGroups();
    }, [authState]);

    const requiresGroup = (groupsAllowed || []).length > 0;
    const userGroups = groups || [];
    const isAllowed = requiresGroup ? userGroups.some((r) => groupsAllowed.indexOf(r) >= 0) : true;

    return isAllowed ? <>{children}</> : null;
};

export default GroupBaseProtect;
