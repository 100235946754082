import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { DrcPanel, DrcTooltip } from 'driscolls-react-components';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import DrcTab from '../../components/DrcTab/DrcTab';
import PointMatrixHeader from '../../components/PointMatrix/PointMatrixHeader';
import PointMatrixPreviewHeader from '../../components/PointMatrix/PointMatrixPreviewHeader';
import PointMatrixTable from '../../components/PointMatrix/PointMatrixTable';
import PointMatrixScoreTable from '../../components/PointMatrix/PointMatrixScoreTable';
import { setPageDirty, setPageTitleAction } from '../../actions/actions';
import { subTabRouting } from '../../util/navigation';
import DirtyCheck from '../../components/DirtyCheck';
import { Grid } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { SCORE, POINT } from '../../language/english';
import GridLayout from '../../components/GridLayout';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { enableFirstRowSelection } from '../../actions/PointMatrixAction';
import { withAuth } from '@driscollsinc/login-module-ui';

const styles = () => ({
    layout: {
        width: '97%'
    },
    gridLayout: {
        marginLeft: 0,
        border: 0,
        paddingLeft: '3px',
        paddingBottom: 0,
        marginBottom: 0
    },
    title: {
        fontSize: '1.3rem'
    },
    card: {
        minHeight: '500px',
        margin: '15px 0',
        backgroundColor: '#eee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    panel: {
        maxWidth: '100%',
        border: 'none',
        boxShadow: '0 2px 4px 0 #C3C3C3',
        padding: '0 15px 10px 15px',
        margin: '0 auto 10px'
    },
    bottom: {
        paddingBottom: 0
    }
});

let changingTabIndex = 0;
const PointMatrixCreateTab = (props) => {
    const [screenHasDataChanges, setScreenHasDataChanges] = useState(false);
    const [showScoreGrid, setShowScoreGrid] = useState(false);
    const [showLookupGrid, setShowLookupGrid] = useState(false);
    const [lookupDataPayload, setLookupDataPayload] = useState([]);
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: ''
    });
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let pageTitle = subTabRouting(pathname);
        props.setPageTitle(pageTitle);
    }, [pathname]);

    const continueNavigation = () => {
        if (changingTabIndex === 0) {
            navigate('/PointMatrix/Create/');
            props.setPageTitle('Point Matrix Create');
        } else if (changingTabIndex === 1) {
            navigate('/PointMatrix/Preview/');
            props.setPageTitle('Point Matrix Preview');
        }
    };

    const handleSubTabChange = (index) => {
        changingTabIndex = index;
        if (props.isPageDirty) {
            setScreenHasDataChanges(true);
            return;
        }
        continueNavigation();
    };

    const handleConfirmReject = () => {
        setScreenHasDataChanges(false);
        props.setPageDirty(false);
        continueNavigation();
    };

    const handleCancelNavigation = () => {
        setScreenHasDataChanges(false);
    };

    const handleDisplayMessage = (message, severity) => {
        if (severity === 'success') {
            setShowLookupGrid(false);
        }
        setMessageDialog({
            showMessage: true,
            message: message,
            severity: severity
        });
    };
    const handleLookupPreview = (data) => {
        setShowLookupGrid(true);
        setLookupDataPayload(data);
    };
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    const handleSearch = (show) => {
        setShowScoreGrid(show);
        props.enableFirstRowSelection(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const { classes, selectedSubTabIndex, subTabItems } = props;
    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', padding: '0 15px 10px 15px', margin: '0 auto 10px' }}
            className={props.classes.layout}
        >
            <DrcTab
                style={{ maxWidth: '100%' }}
                selectedMenuIndex={selectedSubTabIndex}
                menuItems={subTabItems}
                handleMenuItemChange={handleSubTabChange}
                // centered
            ></DrcTab>

            <DrcPanel style={{ maxWidth: '100%' }}>
                {!props.selectedSubTabIndex ? (
                    <>
                        <PointMatrixHeader
                            selectedMenuIndex={selectedSubTabIndex}
                            handleDisplayMessage={handleDisplayMessage}
                            handleLookupPreview={handleLookupPreview}
                        ></PointMatrixHeader>
                    </>
                ) : (
                    <PointMatrixPreviewHeader selectedMenuIndex={selectedSubTabIndex} handleSearch={handleSearch}></PointMatrixPreviewHeader>
                )}
            </DrcPanel>
            {!props.selectedSubTabIndex && showLookupGrid && (
                <PointMatrixScoreTable lookupDataPayload={lookupDataPayload} handleDisplayMessage={handleDisplayMessage} />
            )}
            {!!props.selectedSubTabIndex && (
                <Grid container>
                    <Grid container spacing={2} justify="space-between" alignItems="flex-start">
                        <PointMatrixTable scoreTableRows={props.lookUp} />
                    </Grid>
                </Grid>
            )}
            {screenHasDataChanges && (
                <DirtyCheck
                    screenHasDataChanges={screenHasDataChanges}
                    handleConfirmReject={handleConfirmReject}
                    handleCancelNavigation={handleCancelNavigation}
                ></DirtyCheck>
            )}
            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </DrcPanel>
    );
};

const mapStateToProps = (state) => {
    return {
        isPageDirty: state.rootReducer.isPageDirty,
        lookUp: state.pointMatrixReducer.lookUp
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPageDirty: (isDirty) => dispatch(setPageDirty(isDirty)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    enableFirstRowSelection: (isEnable) => dispatch(enableFirstRowSelection(isEnable))
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withStyles(styles)(PointMatrixCreateTab)));
