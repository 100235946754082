import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/styles/withStyles';
import { startOfWeek } from 'date-fns';
import { DrcButton, DrcInput, DrcSelect } from 'driscolls-react-components';
import { Middleware } from 'one-ring';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { setPageDirty } from '../../actions/actions';
import {
    getPointMatrixPreviewData,
    lookUpValues,
    resetHeader,
    setBerryType,
    setEstimatedGroup,
    setLabel,
    setVariety,
    setDistrictGroup
} from '../../actions/PointMatrixAction';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import {
    DISTRICT_GROUP,
    DISTRICT_GROUP_PLACEHOLDER,
    ESTIMATE_GROUP,
    ESTIMATE_GROUP_PLACEHOLDER,
    LABEL,
    LABEL_PLACEHOLDER,
    MAX_POINTS,
    MIN_POINTS,
    POOL_WEEK_RANGE,
    POOL_WEEK_RANGE_PLACEHOLDER,
    PREVIEW,
    PRODUCT_ATTRIBUTE,
    SPREAD,
    VARIETY
} from '../../language/english';
import APIEndPoints from '../../services/api';
import { getPointMatrixPayload } from '../../util/utils';
import BerryTypeSelect from '../BerryTypeSelect';
import DrcDateRangePicker from './DrcDateRangePicker';
import cloneDeep from 'lodash/cloneDeep';
import { withAuth } from '@driscollsinc/login-module-ui';
const defaultVariety = { value: 'Default', label: 'Default' };
const allowedNumberValuesRegex = RegExp(/^[0-9]*$/);

let styles = () => ({
    dialog: {
        '& .MuiToolbar-root': {
            display: 'none'
        }
    },
    datepickerToolbar: {
        '& .MuiDialogActions-root': {
            display: 'none'
        }
    }
});

const PAGE_TYPE = 'pointMatrix';
const PointMatrixHeader = (props) => {
    const [currentToken, setCurrentToken] = useState('');
    const [pointMatrix, setPointMatrix] = useState({
        districtGroup: '',
        label: '',
        berryType: '',
        estimatedGroup: '',
        productAttribute: 'Brix',
        variety: props.variety[0],
        startDate: new Date(),
        endDate: new Date(),
        poolWeek: '',
        minPoint: '',
        maxPoint: '',
        spread: '',
        isDefault: true,
        helperText: {},
        hideDateDisplay: true
    });
    const refContainer = useRef(null);

    useEffect(() => {
        props.lookUpValues([]);
        props.getPointMatrixPreviewData([]);
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('label', token, APIEndPoints.GET_LABEL, 'GET', { Type: 'label' }, null).then((res) => {
                if (res.Data.length) {
                    props.setLabel(res.Data);
                    getEstimatedGroups('BLACK');
                }
            });
            getDistrictGroups();
        });
    }, []);

    useEffect(() => {
        if (props.isResetHeader) {
            refContainer.current.handleResetSelection();
            props.resetHeader(false);
            setPointMatrix({
                districtGroup: '',
                label: '',
                berryType: '',
                estimatedGroup: '',
                productAttribute: 'Brix',
                variety: defaultVariety,
                startDate: new Date(),
                endDate: new Date(),
                poolWeek: '',
                minPoint: '',
                maxPoint: '',
                spread: '',
                isDefault: true,
                helperText: {}
            });
            props.setPageDirty(false);
        }
    }, [props.isResetHeader]);

    const getEstimatedGroups = (berryType) => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('estimatedGroup', token, APIEndPoints.QUARTILE_ESTIMATED_GROUP(berryType), 'GET', { Type: 'estimatedGroup' }, null).then(
                (res) => {
                    if (res.Data.length) {
                        props.setEstimatedGroup(res.Data);
                    }
                }
            );
        });
    };

    const getDistrictGroups = () => {
        props.auth.getAccessToken().then((token) => {
            Middleware.Send('districtGroups', token, APIEndPoints.DISTRICT_GROUPS, 'GET', { Type: 'districtGroups' }, null).then((res) => {
                if (res.Data.length) {
                    props.setDistrictGroup(res.Data);
                }
            });
        });
    };

    const getVariety = (producingArea, berryType) => {
        if (producingArea && berryType) {
            props.auth.getAccessToken().then((token) => {
                Middleware.Send(
                    'variety',
                    token,
                    APIEndPoints.GET_VARIETY(berryType, producingArea),
                    'GET',
                    { Type: 'variety' },
                    { overrideResponseMapping: true, loadingMessage: 'Fetching variety...' }
                ).then((res) => {
                    setCurrentToken(token);
                    if (res.Data.length) {
                        props.setVariety(res.Data);
                    } else {
                        props.setVariety([]);
                    }
                });
            });
        }
    };

    const handleDistrictGroupChange = (option) => {
        props.setPageDirty(true);
        if (option) {
            setPointMatrix({ ...pointMatrix, districtGroup: option, variety: props.variety[0] });
            getVariety(option.value, pointMatrix.berryType.label);
        }
    };

    const handleBerryChange = (option) => {
        props.setPageDirty(true);
        if (option) {
            setPointMatrix({ ...pointMatrix, berryType: option, variety: props.variety[0] });
            getVariety(pointMatrix.districtGroup.value, option.label);
            getEstimatedGroups(option.label);
        }
    };

    const handleLabelChange = (option) => {
        setPointMatrix({ ...pointMatrix, label: option });
        props.setPageDirty(true);
    };

    const handleEstimatedGroupChange = (option) => {
        setPointMatrix({ ...pointMatrix, estimatedGroup: option });
        props.setPageDirty(true);
    };

    const handleVarietyChange = (option) => {
        if (option) {
            setPointMatrix({ ...pointMatrix, variety: option });
        } else {
            setPointMatrix({ ...pointMatrix, variety: props.variety[0] });
        }
        props.setPageDirty(true);
    };

    const handleDateRangeChange = (startDate, endDate) => {
        setPointMatrix({ ...pointMatrix, startDate: startDate, endDate: endDate, isDefault: false });
    };

    const handleMinPointChange = (event) => {
        const { name, value } = event.target;
        let helperText = { ...pointMatrix.helperText };
        if (allowedNumberValuesRegex.test(value) && value.length <= 10) {
            if (pointMatrix.maxPoint && parseInt(value) > parseInt(pointMatrix.maxPoint)) {
                helperText[name] = 'Min point cannot be greater than max point';
            } else {
                delete helperText[name];
            }
            setPointMatrix({ ...pointMatrix, minPoint: event.target.value, helperText: helperText });
            props.setPageDirty(true);
        }
    };

    const handleMaxPointChange = (event) => {
        const { name, value } = event.target;
        let helperText = { ...pointMatrix.helperText };
        if (allowedNumberValuesRegex.test(value) && value.length <= 10) {
            if (value && parseInt(value) < parseInt(pointMatrix.minPoint)) {
                helperText[name] = 'Max point cannot be smaller than min point';
            } else {
                delete helperText[name];
            }
            setPointMatrix({ ...pointMatrix, maxPoint: event.target.value, helperText: helperText });
            props.setPageDirty(true);
        }
    };

    const handleSpreadChange = (event) => {
        const val = event.target.value;
        if (allowedNumberValuesRegex.test(val) && val.length <= 10) {
            setPointMatrix({ ...pointMatrix, spread: event.target.value });
            props.setPageDirty(true);
        }
    };

    const handleReset = (startDate, endDate) => {
        setPointMatrix({ ...pointMatrix, startDate: startDate, endDate: endDate, isDefault: true });
    };

    const handleClose = () => {
        refContainer.current.handleResetSelection();
        setPointMatrix({ ...pointMatrix, isDefault: true });
    };

    const handlePreview = () => {
        let payload = getPointMatrixPayload(pointMatrix, currentToken);
        payload.Operation = 'Preview';
        props.auth.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_POINT_MATRIX, 'POST', payload, {
                overrideResponseMapping: true,
                loadingMessage: 'Creating point matrix lookup...'
            })
                .then((response) => {
                    if (response && response?.resultSet1 && response?.resultSet1?.length) {
                        props.lookUpValues(response.resultSet1);
                        props.handleLookupPreview(payload);
                    }
                })
                .catch((error) => {
                    let message = error?.data?.response?.data?.message || 'Something happened. but I am not sure if it is good or bad!';
                    props.handleDisplayMessage(message, 'error');
                });
        });
    };

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    return (
        <React.Fragment>
            <>
                <Grid container>
                    <Grid container spacing={3} justify="space-between" alignItems="flex-start">
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcSelect
                                required
                                name="districtgroup"
                                label={DISTRICT_GROUP}
                                value={pointMatrix.districtGroup}
                                onChange={handleDistrictGroupChange}
                                options={cloneDeep(props.districtGroup)}
                                placeholder={DISTRICT_GROUP_PLACEHOLDER}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcSelect
                                required
                                name="Label"
                                label={LABEL}
                                value={pointMatrix.label}
                                onChange={handleLabelChange}
                                options={props.label}
                                placeholder={LABEL_PLACEHOLDER}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <BerryTypeSelect
                                required
                                value={pointMatrix.berryType}
                                onChange={handleBerryChange}
                                options={props.berryType}
                            ></BerryTypeSelect>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcSelect
                                required
                                name={ESTIMATE_GROUP}
                                label={ESTIMATE_GROUP}
                                value={pointMatrix.estimatedGroup}
                                onChange={handleEstimatedGroupChange}
                                options={props.estimateGroup}
                                placeholder={ESTIMATE_GROUP_PLACEHOLDER}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="flex-start">
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcInput
                                label={PRODUCT_ATTRIBUTE}
                                value={pointMatrix.productAttribute}
                                InputLabelProps={{ shrink: true }}
                                name="ProductAttribute"
                                readOnly={true}
                            ></DrcInput>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcSelect
                                name="Variety"
                                label={VARIETY}
                                value={pointMatrix.variety}
                                onChange={handleVarietyChange}
                                isDisabled={!pointMatrix.berryType || !pointMatrix.districtGroup}
                                options={props.variety}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <DrcDateRangePicker
                                ref={refContainer}
                                style={{ color: '#563e70' }}
                                label={POOL_WEEK_RANGE}
                                name="PoolWeek"
                                color="#6f5091"
                                onChange={handleDateRangeChange}
                                onClose={handleClose}
                                onReset={handleReset}
                                months={2}
                                direction="horizontal"
                                placeholder={POOL_WEEK_RANGE_PLACEHOLDER}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                weekDisplay={true}
                                startDate={pointMatrix.startDate}
                                endDate={pointMatrix.endDate}
                                defaultValue={pointMatrix.isDefault}
                                minDate={startOfWeek(new Date())}
                                hideDateDisplay={pointMatrix.hideDateDisplay}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="flex-start">
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                            <DrcInput
                                required
                                label={MIN_POINTS}
                                value={pointMatrix.minPoint}
                                InputLabelProps={{ shrink: true }}
                                name="MinPoints"
                                onChange={handleMinPointChange}
                                readOnly={true}
                                helperText={pointMatrix.helperText['MinPoints']}
                            ></DrcInput>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                            <DrcInput
                                requied
                                label={MAX_POINTS}
                                value={pointMatrix.maxPoint}
                                InputLabelProps={{ shrink: true }}
                                name="MaxPoints"
                                onChange={handleMaxPointChange}
                                helperText={pointMatrix.helperText['MaxPoints']}
                            ></DrcInput>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2}>
                            <DrcInput
                                required
                                label={SPREAD}
                                value={pointMatrix.spread}
                                InputLabelProps={{ shrink: true }}
                                name="Spread"
                                onChange={handleSpreadChange}
                            ></DrcInput>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <DrcButton
                                style={{ margin: '8px 0' }}
                                floatRight
                                isPrimary
                                disabled={
                                    !pointMatrix.districtGroup ||
                                    !pointMatrix.berryType ||
                                    !pointMatrix.spread ||
                                    !pointMatrix.maxPoint ||
                                    !pointMatrix.minPoint ||
                                    !pointMatrix.label ||
                                    !pointMatrix.estimatedGroup ||
                                    Object.keys(pointMatrix.helperText).length
                                }
                                onClick={handlePreview}
                            >
                                {PREVIEW}
                            </DrcButton>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        estimateGroup: state.pointMatrixReducer.estimateGroup,
        districtGroup: state.pointMatrixReducer.districtGroup,
        berryType: state.pointMatrixReducer.berryType,
        label: state.pointMatrixReducer.label,
        variety: state.pointMatrixReducer.variety,
        previewVariety: state.pointMatrixReducer.previewVariety,
        lookUp: state.pointMatrixReducer.lookUp,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        isResetHeader: state.pointMatrixReducer.isResetHeader
    };
};

export default connect(mapStateToProps, {
    setPageDirty,
    setBerryType,
    setLabel,
    setVariety,
    lookUpValues,
    getPointMatrixPreviewData,
    resetHeader,
    setEstimatedGroup,
    setDistrictGroup
})(withAuth(withStyles(styles)(PointMatrixHeader)));
