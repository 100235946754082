import { useAuth } from '@driscollsinc/login-module-ui';
import { useEffect } from 'react';

const LogOut = () => {
    const { logout } = useAuth();

    useEffect(() => {
        logout('/');
    }, []);

    return (
        <main>
            <h3>Please Wait... Logging you out shortly...</h3>
        </main>
    );
};

export default LogOut;
