import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import Warning from '@material-ui/icons/Warning';
import { useAuth, withAuth } from '@driscollsinc/login-module-ui';

const styles = (theme) => ({
    heading: {
        display: 'inline-block',
        margin: '20px 20px 0 20px',
        position: 'relative',
        top: '-15px'
    },
    icon: {
        fontSize: '4rem'
    }
});

const DrcPageNotAuthorized = ({ logOutUrl, logInUrl, classes, authState }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const { isAuthenticated: getIsAuthenticated } = useAuth();

    const checkForAuthentication = async () => {
        const isAuthenticated = authState.isAuthenticated;

        try {
            const isAuth = await getIsAuthenticated();
            setIsAuthenticated(isAuth);
        } catch (error) {
            console.error('ERROR: Authentication not available');
            if (isAuthenticated !== false) {
                setIsAuthenticated(false);
            }
        }
    };

    useEffect(() => {
        checkForAuthentication();
    }, [authState]);

    return (
        <DrcMain transparent>
            <Helmet>
                <title>Not Authorized</title>
            </Helmet>
            <DrcPanel error maxWidth="500px" style={{ textAlign: 'center' }}>
                <Warning className={classes.icon} />
                <h1 className={classes.heading}>You are not authorized!</h1>
                <Warning className={classes.icon} />
                <br />
                {isAuthenticated ? (
                    <p>
                        Please <NavLink to={logOutUrl || '/LogOut/'}>Sign-Out</NavLink> and try with better credentials.
                    </p>
                ) : (
                    <p>
                        Please go back to the <NavLink to={logInUrl || '/'}>Sign-In</NavLink> page and try again.
                    </p>
                )}
            </DrcPanel>
        </DrcMain>
    );
};

export default withAuth(withStyles(styles)(DrcPageNotAuthorized));
